(() => {
  const init = () => {
    let $mainHeader: HTMLElement;
    let $extLinksToggler: HTMLElement;
    let $resourceLinksToggler: HTMLElement;
    let winWidth = window.innerWidth;
    let $dropdownContainer: HTMLElement;
    let isTouchDevice = window.matchMedia('(pointer: coarse)').matches !== true;

    const setHeaderHeightVar = () => {
      if ($mainHeader) {
        const root = document.querySelector(':root') as HTMLElement;
        const bufferHeight = winWidth < 1024 ? 40 : 0;
        root.style.setProperty(
          '--header-height',
          $mainHeader.clientHeight + bufferHeight + 'px'
        );
      }
    };

    const initVariables = () => {
      $mainHeader = document.querySelector('#main-header') as HTMLElement;
      $extLinksToggler = $mainHeader.querySelector(
        '.main-header__external-links-visibility-toggler'
      ) as HTMLElement;
      $resourceLinksToggler = $mainHeader.querySelector(
        '#main-header__resource-links-visibility-toggler'
      ) as HTMLElement;
      $dropdownContainer = $mainHeader.querySelector(
        '.main-header__revealable-resource-links-container'
      ) as HTMLElement;
    };

    const appendEvents = () => {
      window.Bus.on('emu-button:click', ({ id, toggleOn }) => {
        // when external link close icon is clicked, collapse the external links menu
        if (id === 'main-header__close-external-links-btn') {
          $extLinksToggler?.click?.();
        }

        // when external links expand toggler is clicked, collapse the resources dropdown if it is in open state, when in desktop mode
        if (id === 'main-header__external-links-visibility-toggler') {
          if (
            $resourceLinksToggler?.classList.contains('js-toggle-on') &&
            winWidth >= 1024
          ) {
            $resourceLinksToggler.click();
          }
        }

        // when resources dropdown is open in non touch devices, when the menu is hovered out, close the menu
        if (id === 'main-header__resource-links-visibility-toggler') {
          if (toggleOn && isTouchDevice) {
            $dropdownContainer.addEventListener(
              'mouseleave',
              () => {
                $resourceLinksToggler?.click?.();
              },
              {
                once: true,
              }
            );
          }
        }
      });

      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          setHeaderHeightVar();
        }
      });
    };

    initVariables();
    appendEvents();
    setHeaderHeightVar();
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
