// Stylesheets
import './main.scss';

// Imports all component based scss & ts files.
import './components/**/*.scss';
import './components/**/*.ts';
import './flippable-cards';
import './image-map-v1';
import './image-map-v2';

// import favicon here.
import './resources/images/favicon.png';

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    Bus: any; // event bus
    AAAEM: AAAEM;
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  // when anchors with # are clicked, check and scroll to the page's section
  const handleHashLinks = () => {
    const $hashLinks = document.querySelectorAll(
      '[href*="#"]'
    ) as NodeListOf<HTMLAnchorElement>;
    const $mobileNavTrigger = document?.querySelector(
      '.main-header__mobile-nav-visibility-toggler'
    ) as HTMLButtonElement;

    $hashLinks.forEach($el => {
      const href = $el.getAttribute('href') || '';
      const hash = href?.split('#')[1];

      if (hash) {
        let elIsInMenu = false;
        if ($el.closest('#main-header')) {
          elIsInMenu = true;
        }

        const $section = document.querySelector(`#${hash}`) as HTMLElement;
        if ($section) {
          $el.addEventListener('click', e => {
            e.preventDefault();
            $section.scrollIntoView();

            const $scrollTargetSections = document.querySelectorAll(
              '.u-scroll-target-section'
            );

            $scrollTargetSections.forEach($el => {
              const id = $el.id;
              const $anchors = document.querySelectorAll(`[href="/#${id}"]`);
              $anchors.forEach($anchor => {
                $anchor.classList.remove('scroll-section-active');
              });
            });

            // closing the menu
            if (
              elIsInMenu &&
              $mobileNavTrigger?.classList.contains('js-toggle-on')
            ) {
              $mobileNavTrigger.click?.();
            }

            $el.classList.add('scroll-section-active');

            // adding active class to all elements that has same href
            try {
              const curHash = new URL($el.href).hash;
              document
                .querySelectorAll(`[href="/${curHash}"]`)
                ?.forEach($anchor => {
                  $anchor.classList.add('scroll-section-active');
                });
            } catch (e) {
              console.warn(e);
            }
          });
        }
      }
    });
  };

  // for every element with u-scroll-target-section class,
  // whenever the section is visible, add an active class to the anchor elements that has same hash as the ID for the scroll section
  // it is mandatory that the anchors should have the hash as the follows '/#[ID_OF_THE_SECTION]
  const handleScrollTargetSections = () => {
    const $scrollTargetSections = document.querySelectorAll(
      '.u-scroll-target-section'
    );

    const checkVisibility = () => {
      const viewportHeight = window.innerHeight;
      $scrollTargetSections.forEach($el => {
        const id = $el.id;
        const $anchors = document.querySelectorAll(`[href="/#${id}"]`);

        if ($anchors?.length) {
          const rect = $el.getBoundingClientRect();

          const percentViewportHeight = viewportHeight * 0.2;

          const isInViewportFromBottom =
            rect.top < viewportHeight - percentViewportHeight &&
            rect.bottom > viewportHeight - percentViewportHeight;

          const isOutOfViewport = rect.top > viewportHeight || rect.bottom < 0;

          if (isInViewportFromBottom) {
            document
              .querySelectorAll('.scroll-section-active')
              .forEach($activeAnchor => {
                $activeAnchor.classList.remove('scroll-section-active');
              });
            $anchors.forEach($anchor => {
              $anchor.classList.add('scroll-section-active');
            });
          }

          if (isOutOfViewport) {
            $anchors.forEach($anchor => {
              $anchor.classList.remove('scroll-section-active');
            });
          }
        }
      });
    };

    window.addEventListener('touchmove', checkVisibility);
    window.addEventListener('wheel', checkVisibility);
    window.addEventListener('resize', checkVisibility);
  };

  const handleURLhash = () => {
    const { hash } = window.location;
    if (hash) {
      const $section = document.querySelector(hash) as HTMLElement;
      $section?.scrollIntoView();
    }
  };

  const handleElementVisibility = (
    selector: string,
    toggleSelector: string
  ) => {
    const $element = document.querySelector(selector) as HTMLElement;
    const $body = document.body;

    const observer = new IntersectionObserver(e => {
      $body?.classList.toggle(toggleSelector, e[0].isIntersecting);
    });

    if ($element) {
      observer.observe($element);
    }
  };

  const handleVideoAutoplay = () => {
    const $vimeoAutoplayEls = document.querySelectorAll(
      '.autoplay-on-visibility'
    );
    $vimeoAutoplayEls.forEach($el => {
      const id = $el.id;
      const inst = window._plyrInstances[id];
      if (id && inst) {
        const checkAndPlay = () => {
          const rect = $el.getBoundingClientRect();
          const viewportHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // making sure that the video crosses 3/4th of the viewport height
          if (rect.top < viewportHeight * 0.75) {
            // play the video
            if (inst.playing === false) {
              inst.volume = 0;
              queueMicrotask(() => {
                inst.play?.();
              });
            }
            window.removeEventListener('scroll', checkAndPlay);
          }
        };

        window.addEventListener('scroll', checkAndPlay);
      }
    });
  };

  const init = () => {
    handleHashLinks();
    handleScrollTargetSections();
    handleURLhash();
    handleElementVisibility('section.references', 'references-in-view');
    handleElementVisibility('footer.experiencefragment', 'footer-in-view');
    handleVideoAutoplay();
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
