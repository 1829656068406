(() => {
  const config = {
    rootSelector: '.profile-img-map',
    contentClassName: 'profile-img-map__content',
    activeContentModifier: 'active',
    markerClassName: 'emu-mapping-marker',
  } as const;

  const waitForElm = (selector: string, parent: Element) => {
    return new Promise<Element>(resolve => {
      const wrapper = parent || document;
      const resolvePromiseTest = () => {
        const el = wrapper.querySelector(selector);
        if (el !== null && el.getBoundingClientRect().width > 0) {
          return el;
        }
        return null;
      };
      const res = resolvePromiseTest();
      if (res !== null) {
        resolve(res);
        return;
      }
      const observer = new MutationObserver(() => {
        const res = resolvePromiseTest();
        if (res !== null) {
          resolve(res);
          observer.disconnect();
        }
      });
      observer.observe(parent || document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  const init = () => {
    const activeContentClassName = `${config.contentClassName}--${config.activeContentModifier}`;
    window.Bus.on(
      'emu-image:inview',
      ({ element }: { element: HTMLElement }) => {
        if (element.closest(config.rootSelector) === null) {
          return;
        }
        waitForElm(`${config.rootSelector} .${config.markerClassName}`, element)
          .then(marker => {
            if (marker instanceof HTMLButtonElement) {
              marker.click();
            }
          })
          .catch(console.error);
      }
    );
    window.Bus.on('emu-image-map:areaClick', ({ id, isActive }) => {
      let marker: HTMLElement | null = null;
      let root: HTMLElement | null = null;
      let content: HTMLElement | null = null;
      if (
        typeof id !== 'string' ||
        typeof isActive !== 'boolean' ||
        !(marker = document.getElementById(id)) ||
        !(root = marker.closest(config.rootSelector)) ||
        !(content = root.querySelector(`.${config.contentClassName}`))
      ) {
        return;
      }
      if (isActive) {
        content.style.top = marker.style.top;
        content.style.left = marker.style.left;
        const { width, height } = getComputedStyle(marker);
        content.style.setProperty('--marker-width', width);
        content.style.setProperty('--marker-height', height);
        content.classList.add(activeContentClassName);
      } else {
        content.classList.remove(activeContentClassName);
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
