(() => {
  // on resize and on expand of accordion, making sure that the height of the accordion content is accurate
  const handleAccordionHeight = () => {
    const accordion = document.querySelector('.aaaem-accordion');
    if (accordion) {
      let winWidth = window.innerWidth;
      const badgerInstances = window._badgerInstances;
      const badgerKeys = badgerInstances && Object.keys(badgerInstances);
      if (badgerKeys?.length) {
        const resizeBadgerHeights = () => {
          badgerKeys.forEach(key => {
            window._badgerInstances[key].calculateAllPanelsHeight?.();
          });
        };

        // making sure the height of the accordion content is accurate on load.
        window.addEventListener('load', () => {
          resizeBadgerHeights();
        });

        // making sure the height of the accordion content is accurate on resize.
        window.addEventListener('resize', () => {
          const curWidth = window.innerWidth;
          if (winWidth !== curWidth) {
            winWidth = curWidth;
            resizeBadgerHeights();
          }
        });
      }
    }

    window.Bus.on('emu-accordion:toggle', ({ id, itemClicked, items }) => {
      const badgerInstance = window._badgerInstances[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (accordionContent) {
          badgerInstance.calculatePanelHeight(accordionContent);
        }

        const hasOpenedAcc = items.filter(e => e.opened)?.length > 0;
        accEl.classList.toggle(
          'aaaem-accordion--has-expanded-item',
          hasOpenedAcc
        );
      }
    });
  };

  const init = () => {
    handleAccordionHeight();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
