(() => {
  const config = {
    flipperSelector: '.flippable-card__flipper',
    flipSectionSelector: '.flippable-card__flip-section',
    flippedClassName: 'flipped',
  } as const;

  const findInstancesOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): Array<T> => {
    const res: Array<T> = [];
    const elements = searchOn.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element instanceof isInstanceOf) {
        res.push(element);
      }
    }
    return res;
  };

  // if there is a flippable element on the page with class flippable-card--auto-flip,
  // when the user scrolls the page, and as soon as the element passes more than half the screen, automatically flip the card
  const appendAutoFlip = ($btn: HTMLButtonElement) => {
    if ($btn) {
      const $autoFlipCard = $btn.closest('.flippable-card--auto-flip');

      if ($autoFlipCard) {
        const alwaysFlipCard = $autoFlipCard?.classList.contains(
          'flippable-card--auto-flip-always'
        );

        const checkAndFlip = () => {
          const rect = $autoFlipCard.getBoundingClientRect();
          const viewportHeight =
            window.innerHeight || document.documentElement.clientHeight;

          if (rect.top < viewportHeight / 2) {
            $btn.click();
            if (alwaysFlipCard !== true) {
              window.removeEventListener('scroll', checkAndFlip);
            }
          }
        };

        window.addEventListener('scroll', checkAndFlip);
      }
    }
  };

  const init = () => {
    const flippableCards = findInstancesOf(
      config.flipperSelector,
      HTMLButtonElement
    );

    for (const flipBtn of flippableCards) {
      const flipSection = flipBtn.closest(config.flipSectionSelector);
      if (flipSection === null) {
        continue;
      }

      flipBtn.addEventListener('click', () => {
        flipSection.classList.toggle(config.flippedClassName);
      });

      appendAutoFlip(flipBtn);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
