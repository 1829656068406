(() => {
  const config = {
    rootSelector: '.img-map__main',
    contentClassName: 'img-map__content',
    markerClassName: 'emu-mapping-marker',
    alignContent: 'img-map__content--align-content',
    activeContentModifier: 'active',
  } as const;

  // wait for element to be present on the screen and resolves with a promise
  const waitForElm = (selector, parent?) => {
    return new Promise(resolve => {
      const wrapper = parent || document;
      const observeParent = parent || document.body;
      let el = wrapper.querySelector(selector);

      if (el) {
        resolve(el);
        return;
      }

      const observer = new MutationObserver(() => {
        if (wrapper?.querySelector(selector)) {
          resolve(wrapper.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(observeParent, {
        childList: true,
        subtree: true,
      });
    });
  };

  const handleImgMap = ($img: HTMLImageElement) => {
    let markerMap = {},
      imgMapId: string = '',
      contentActiveClass: string = `${config.contentClassName}--${config.activeContentModifier}`,
      markerActiveClass: string = `${config.markerClassName}--${config.activeContentModifier}`,
      $markers: NodeListOf<HTMLElement>,
      $imgMain: HTMLElement,
      activeKey: string = '';

    // creating a marker object to keep track of the active marker
    const createMarkerObj = () => {
      $markers?.forEach($marker => {
        const markerLabel = $marker.getAttribute('aria-label');
        const $content = $imgMain.querySelector(
          `.${config.contentClassName}--${markerLabel}`
        ) as HTMLElement;

        if (markerLabel && $content instanceof HTMLElement) {
          $content.setAttribute('data-label', markerLabel);

          const $imgMapMainContainer = document.querySelector(
            config.rootSelector
          ) as HTMLElement;

          const markerPositionFromViewport = $marker.getBoundingClientRect();
          const mainContainerPositionFromViewport =
            $imgMapMainContainer.getBoundingClientRect();

          // Calculate the position of the target relative to the reference
          const markerPosFromTop =
            markerPositionFromViewport.top -
            mainContainerPositionFromViewport.top;

          const markerPosFromLeft =
            markerPositionFromViewport.left -
            mainContainerPositionFromViewport.left;

          const contentCardWidth = $content.getBoundingClientRect().width;
          const contentCardHeight = $content.getBoundingClientRect().height;

          const markerWidth = $marker.getBoundingClientRect().width;
          const markerHeight = $marker.getBoundingClientRect().height;

          const arrowWidth = 18;
          const arrowHeight = 18;

          if (window.innerWidth <= 1023) {
            // for narrow screen

            if (
              $content.classList.contains(
                `${config.alignContent}-left-on-mobile`
              )
            ) {
              $content.style.top = `${
                markerPosFromTop - contentCardHeight / 2
              }px`;

              $content.style.left = `${
                markerPosFromLeft - contentCardWidth - arrowHeight
              }px`;
            } else {
              //default position
              $content.style.top = `${
                markerPosFromTop + markerHeight + arrowHeight
              }px`;

              $content.style.left = `${
                markerPosFromLeft - contentCardWidth / 2 + markerWidth / 2
              }px`;
            }
          } else {
            //for wide screen

            if (
              $content.classList.contains(
                `${config.alignContent}-top-on-desktop`
              )
            ) {
              $content.style.top = `${
                markerPosFromTop - contentCardHeight - arrowHeight
              }px`;

              $content.style.left = `${
                markerPosFromLeft - contentCardWidth / 2 + markerWidth / 2
              }px`;
            } else if (
              $content.classList.contains(
                `${config.alignContent}-right-on-desktop`
              )
            ) {
              $content.style.top = `${
                markerPosFromTop - contentCardHeight / 2
              }px`;

              $content.style.left = `${
                markerPosFromLeft + markerWidth + arrowWidth
              }px`;
            } else {
              //default position
              $content.style.top = `${
                markerPosFromTop + markerHeight + arrowHeight
              }px`;

              $content.style.left = `${
                markerPosFromLeft - contentCardWidth / 2 + markerWidth / 2
              }px`;
            }
          }

          markerMap[markerLabel] = {
            $content,
            markerLabel,
            $marker,
          };
        }
      });
    };

    // makes a marker active, adds appropriate classes to the body, marker and corresponding content
    const makeMarkerObjActive = markerObj => {
      activeKey = markerObj.markerLabel;

      markerObj.$content.classList.add(contentActiveClass);
      markerObj.$marker.classList.add(markerActiveClass);
    };

    // makes a marker inactive for a provided marker Object
    // if marker object is not provided, loops through the map, finds the active marker and makes it inactive
    const makeMarkerObjInactive = (markerObj?) => {
      let obj = markerObj;
      if (!obj) {
        Object.keys(markerMap).forEach(markerKey => {
          if (activeKey === markerKey) {
            obj = markerMap[markerKey];
          }
        });
      }

      if (obj?.$marker) {
        activeKey = '';
        obj.$content.classList.remove(contentActiveClass);
        obj.$marker.classList.remove(markerActiveClass);
      }
    };

    const handleMapAreaClick = (title: string) => {
      const markerObj = markerMap[title];
      if (markerObj) {
        if (activeKey && activeKey === title) {
          makeMarkerObjInactive(markerObj);
        } else {
          if (activeKey) {
            makeMarkerObjInactive();
          }
          createMarkerObj();
          makeMarkerObjActive(markerObj);
        }
      }
    };

    const initVariables = () => {
      $imgMain = $img.closest(config.rootSelector) as HTMLElement;

      const $imgMapContainer = $img.closest('.emu-image-map') as HTMLElement;
      $markers = $imgMapContainer?.querySelectorAll(
        `.${config.markerClassName}`
      );

      createMarkerObj();
      imgMapId = $img.getAttribute('data-id')!;
    };

    const handleClickOutside = (event: MouseEvent) => {
      const marker = (event.target as HTMLElement).parentElement;

      if (
        marker &&
        marker.classList.contains('emu-mapping-marker') &&
        marker.closest('.img-map__main')
      ) {
        return;
      } else {
        const activeContent = $imgMain.querySelector(
          `.${config.markerClassName}--${config.activeContentModifier}`
        );
        if (activeContent && !activeContent.contains(event.target as Node)) {
          makeMarkerObjInactive();
        }
      }
    };

    const appendEvents = () => {
      // when marker is clicked, show the corresponding content
      window.Bus.on('emu-image-map:areaClick', ({ id, title }) => {
        if (id?.includes?.(imgMapId)) {
          handleMapAreaClick(title);
        }
      });

      document.addEventListener('click', handleClickOutside);
    };

    initVariables();
    appendEvents();
  };

  const updateImageMap = () => {
    const imgMapElems = document.querySelectorAll(
      '.img-map__main'
    ) as NodeListOf<HTMLElement>;

    imgMapElems.forEach(imgMap => {
      // markers will not be available until JS from aaaem is processed.
      // waiting for the markers to appear before adding any functionality
      waitForElm(`.${config.markerClassName}`, imgMap).then(() => {
        const imgs = imgMap.querySelectorAll(
          '.emu-image-map'
        ) as NodeListOf<HTMLImageElement>;

        if (imgs?.length) {
          if (window.innerWidth <= 1023) {
            handleImgMap(imgs[0]); //image map for narrow screen
          } else {
            handleImgMap(imgs[1]); //image map for wide screen
          }
        }
      });
    });
  };

  const init = () => {
    updateImageMap();

    window.addEventListener('resize', updateImageMap);
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
