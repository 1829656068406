(() => {
  const init = () => {
    const { classList } = document.documentElement;
    const isiExpandedOnceClass = 'isi-expanded-once';

    window.Bus.on('emu-button:click', details => {
      const trigger = document.getElementById(details.id);
      if (trigger === null) {
        return;
      }
      const banner = trigger.closest('.aaaem-isi-banner');
      if (banner !== null) {
        document.documentElement.style.setProperty(
          '--isiBannerHeight',
          `${banner.getBoundingClientRect().height}px`
        );
        const isiExpandedClass = 'isi-expanded';

        if (details.toggleOn) {
          classList.add(isiExpandedClass);

          if (!classList.contains(isiExpandedOnceClass)) {
            classList.add(isiExpandedOnceClass);
          }
        } else {
          classList.remove(isiExpandedClass);
        }
      }
    });

    document.addEventListener(
      'scroll',
      () => {
        classList.add(isiExpandedOnceClass);
      },
      {
        once: true,
      }
    );
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
